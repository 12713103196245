var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login"},[_c('h3',[_vm._v("登录")]),_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{staticClass:"form-item"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'username',
        { rules: [{ required: true, message: '请输入账号！' }] } ]),expression:"[\n        'username',\n        { rules: [{ required: true, message: '请输入账号！' }] },\n      ]"}],staticClass:"input-item",attrs:{"placeholder":"输入账号：admin123"}})],1),_c('a-form-item',{staticClass:"form-item"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'password',
        { rules: [{ required: true, message: '请输入密码！!' }] } ]),expression:"[\n        'password',\n        { rules: [{ required: true, message: '请输入密码！!' }] },\n      ]"}],staticClass:"input-item",attrs:{"type":"password","placeholder":"密码：admin456"}})],1),_c('a-form-item',{staticStyle:{"margin":"0"}},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'remember',
        {
          valuePropName: 'checked',
          initialValue: false,
        } ]),expression:"[\n        'remember',\n        {\n          valuePropName: 'checked',\n          initialValue: false,\n        },\n      ]"}],staticClass:"remomber",staticStyle:{"color":"rgba(102, 102, 102, 1)"}},[_vm._v("记住密码")])],1),_c('a-form-item',{staticClass:"form-item"},[_c('a-button',{staticClass:"login-form-button",attrs:{"block":"","type":"primary","html-type":"submit","loading":_vm.loading}},[_vm._v("登入")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }