<!--
 * @Author: JiaQi
 * @Date: 2021-03-04 21:46:28
 * @LastEditors: JiaQi
 * @LastEditTime: 2021-03-06 11:56:32
 * @Description: 注册弹窗
-->
<style lang="less" scoped>
.pwd-wrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  .pwd-logo {
    width: 167px;
    height: 62px;
    position: absolute;
    left: 107px;
    top: 55px;
  }
  .close {
    width: 34PX;
    height: 34PX;
    background-image: url("../../assets/images/close.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 34PX;
    position: absolute;
    top: 45px;
    right: 53px;
  }
  .pwd-title {
    // width: 496px;
    margin-top: 124px;
    font-size: 40px;
    color: #666666;
    text-align: center;
  }
  .pwd-content {
    // width: 496px;
    margin-top: 42px;
    ::v-deep .ant-form-item-control-wrapper {
      flex: 1;
    }
    ::v-deep .ant-form-item-label {
      width: 96px;
      height: 46px;
      
    }
    ::v-deep .ant-form-item-control-wrapper {
      height: 46px;
      display: flex;
      flex-direction: row;
      align-items: center;
      .ant-form-item-control {
        flex: 1;
      }
      .ant-form-item-children {
        display: flex;
        flex-direction: row;
        align-items: center;
        .ant-input {
          border: 1px solid #efefef;
          background-color: #fcfcfc;
        }
        .send-sms {
          margin-left: 8px;
          border: 1px solid #437cf3;
          color: #447df5;
          font-weight: bold;
          background-color: #f5f8fe;
          height: 46px;
        }
      }
    }
    ::v-deep .ant-form-item-control-wrapper,
    ::v-deep .ant-form-item-control,
    ::v-deep .ant-form-item-children,
    ::v-deep .ant-input {
      height: 46px;
      line-height: 46px;
      color: #333333;
      font-size: 16px;
    }
    ::v-deep .ant-form-item-control-wrapper,
    ::v-deep .ant-form-item-control,
    ::v-deep .ant-form-item-children,
    ::v-deep .ant-select-enabled,
    ::v-deep .ant-select-selection,
    ::v-deep .ant-select-selection__rendered {
      height: 46px;
      line-height: 46px;
    }

    .save-button {
      width: 480px;
      height: 60px;
      margin-top: 20px;
      border-radius: 4px;
      background-color: #447df5;
      color: white;
      font-size: 24px;
      font-weight: bold;
    }
  }
}

.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
  transform: scale(0.5);
}
.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: all 0.3s ease;
}
</style>

<template>
  <div class="pwd">
    <transition name="modal-fade">
      <div v-if="status === 1" class="pwd-wrapper">
        <img src="@assets/images/logo.png" alt="" class="pwd-logo" />
        <div class="close" @click="hide"></div>
        <div class="pwd-title">修改密码</div>
        <div class="pwd-content">
          <template>
            <a-form-model ref="pwdForm" :model="form" :rules="rules">
              <a-form-model-item
                label="旧密码"
                prop="initPwd"
                ref="initPwd"
              >
                <a-input
                  v-model="form.initPwd"
                  placeholder="请输入旧密码"
                />
              </a-form-model-item>
              <a-form-model-item label="新密码" prop="newPwd" ref="newPwd">
                <a-input v-model="form.newPwd" placeholder="请输入新密码" />
              </a-form-model-item>
              <a-form-model-item
                label="确认密码"
                prop="affirmPwd"
                ref="affirmPwd"
              >
                <a-input
                  v-model="form.affirmPwd"
                  placeholder="请输入确认密码"
                />
              </a-form-model-item>
            </a-form-model>
          </template>     
          <button type="save" class="save-button" @click="onSubmit">保存</button>    
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Storage from "@/utils/storage";
import { fetchAdminModifypass } from "@/api/admin.js";
import md5 from 'js-md5';
import router from '@/router/index'

export default {
  data() {
    return {
      inviter: undefined,
      loading: false,
      status: 0,
      form: {
        initPwd: undefined,
        newPwd: undefined,
        affirmPwd: undefined,
      },
      rules: {
        initPwd: {
          required: true,
          message: "",
        },
        newPwd: {
          required: true,
          message: "",
        },
        affirmPwd: {
          required: true,
          message: "",
        },
      },
    };
  },
  created() {
    
  },
  methods: {
    /// 修改密码验证
    onSubmit() {
      this.$refs.pwdForm.validate((valid) => {
        if (valid) {
          if (this.form.newPwd != this.form.affirmPwd) {
            return this.$message.error("请再次两次密码是否一致");_
          }
          this.changePwd()
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /// 获取排行
    async changePwd() {
      const that = this;
      try {
        await fetchAdminModifypass({
          new_pass: md5(this.form.newPwd),
        });
        this.$message.success("密码修改成功，请重新登录");
        Storage.remove('password');
        Storage.remove('admin_id');
        sessionStorage.removeItem('password')
        sessionStorage.removeItem('admin_id')
        setTimeout(() => {
          that.hide();
          router.replace({ name: "login" });
        }, 1000);
      } catch (error) {
        console.log(error);
      }
    },
    show() {
      this.status = 1;
    },
    hide() {
      this.status = 0;
      this.$emit("hide");
    },
  }
};

</script>

