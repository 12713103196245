/*
 * @Author: LazyQ
 * @Date: 2020-04-12 10:11:40
 * @LastEditTime: 2020-04-20 15:13:20
 * @LastEditors: LazyQ
 */
import axios from 'axios'
import message from 'ant-design-vue/es/message'
import Storage from '@/utils/storage'
import router from '@/router/index'

axios.interceptors.request.use(function (config) {
  let url = config.url;
  const pwd = sessionStorage.getItem('password') || localStorage.getItem('password') || '';
  const adminId = sessionStorage.getItem('admin_id') || localStorage.getItem('admin_id') || '';
  if (adminId.length > 0) {
    config.url = url + "?admin_id=" + adminId + "&password=" + pwd;
  }
  // config.headers['Content-Type'] = 'application/json;charset=utf-8';
  // config.headers['X-SESSION-ID'] = Storage.get('session') || sessionStorage.getItem('session');
  return config;
});
//http response 拦截器
axios.interceptors.response.use(
  response => {
    const { data, error, ret } = response.data
    if (ret === 1) {
      return data
    }else if (ret === 204003) {
      /// 重新登录
      let currentRoute = router.currentRoute.name;
      Storage.remove('password');
      Storage.remove('admin_id');
      sessionStorage.removeItem('password')
      sessionStorage.removeItem('admin_id')
      setTimeout(() => {
        // router.replace({ name: 'login', query: { redirect: currentRoute } })
        router.replace({ name: 'login' })
      }, 1000);
    }
    message.error(error);
    return Promise.reject(error)
  },
  error => {
    return Promise.reject(error.response.data)
  }
);

const fetchGet = (url, params = {}) => {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params
    })
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err.response)
      })
  })
};

const fetchPost = (url, data = {}) => {
  return new Promise((resolve, reject) => {
    axios.post(url, data)
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err)
      })
  })
};

const fetchPut = (url, data = {}) => {
  return new Promise((resolve, reject) => {
    axios.put(url, data)
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err)
      })
  })
};

const fetchDelete = (url) => {
  return new Promise((resolve, reject) => {
    axios.delete(url)
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err)
      })
  })
};

export default {
  fetchGet,
  fetchPost,
  fetchPut,
  fetchDelete
}


