<!--
 * @Author: LazyQ
 * @Date: 2020-04-12 11:21:44
 * @LastEditTime: 2020-04-17 15:55:09
 * @LastEditors: LazyQ
 -->
<style lang="less" scoped>
.auth {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("../assets/images/auth.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #447DF5;
  position: relative;
  .auth-wrap {
    width: 456.88px;
    height: 311.88px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    background-color: #fff;
  }
}
</style>

<template>
  <a-layout>
    <a-layout-content class="auth">
      <div class="auth-wrap">
        <router-view></router-view>
      </div>
    </a-layout-content>
  </a-layout>
</template>

<script>
export default {
  components: {}
};
</script>