/*
 * @Author: LazyQ
 * @Date: 2020-11-24 14:56:55
 * @LastEditTime: 2020-11-28 17:46:21
 * @LastEditors: LazyQ
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes';
import AuthLayout from '@/layouts/AuthLayout.vue'
import Login from '@/views/login/Login.vue'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const route = new VueRouter({
  base: '/admin/',
  routes: [...routes, {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        path: 'login',
        name: 'login',
        component: Login,
        meta: { title: '登录', keepAlive: false }
      },
    ]
  }]
})

route.beforeEach((to, from, next) => {
  // 合法性校验
  const session = sessionStorage.getItem('admin_id') || localStorage.getItem('admin_id') || '';
  if (to.meta.needLogin) {  // 判断该路由是否需要登录权限
    if (session) { // 判断是否已经登录
      next()
    } else {
      next({
        name: 'login',
        query: { redirect: to.name }  // 将跳转的路由path作为参数，登录成功后跳转到该路由
      })
    }
  } else {
    next()
  }
  next();
});

export default route;