<!--
 * @Author: LazyQ
 * @Date: 2020-04-12 11:09:01
 * @LastEditTime: 2020-04-17 15:53:55
 * @LastEditors: LazyQ
 -->
<template>
  <a-layout id="components-layout-demo-custom-trigger">
    <a-layout-sider class="sider" width="240px" :trigger="null" collapsible v-model="collapsed">
      <div class="logo"></div>
      <side-menu mode="inline"></side-menu>
    </a-layout-sider>
    <a-layout
      style="margin-left: 240px; width: calc(100% - 240px); min-width: 1200px; position: relative; background: #edf0ed"
    >
      <basic-header></basic-header>
      <a-layout-content class="basic-content">
        <div class="basic-wrapper">
          <router-view></router-view>
        </div>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
import BasicHeader from "./BasicHeader";
import SideMenu from "../components/menu/SiderMenu";
export default {
  components: {
    BasicHeader,
    SideMenu
  },
  data() {
    return {
      collapsed: false,
      menus: []
    };
  },
  created() {},
  methods: {
    menuSelect(e) {
      console.log(e);
    }
  }
};
</script>
<style lang="less" scoped>
#components-layout-demo-custom-trigger {
  height: 100vh;
  .logo {
    width: 240px;
    height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-image: url("../assets/images/logo.png");
    background-position: center;
    background-size: 98.75px 42.5px;
    background-repeat: no-repeat;
  }
  .sider {
    position: fixed;
    left: 0;
    z-index: 1;
    height: 100vh;
    overflow: scroll;
  }
  .ant-layout-sider {
    width: 240px;
    background-image: url("../assets/images/slider.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .basic-content {
    // margin-top: 90px;
    // background: #fff;
    margin: 24px;
    padding-top: 64px;
    // display: table;
    .basic-wrapper {
      background-color: #fff;
      padding: 18px 20px;
      min-height: 100%;
    }
  }
}
</style>