<!--
 * @Author: LazyQ
 * @Date: 2020-04-12 11:21:44
 * @LastEditTime: 2020-04-15 18:24:08
 * @LastEditors: LazyQ
 -->
<style scoped>
.basic-header {
  width: calc(100% - 240px);
  background-color: #fff;
  position: fixed;
  top: 0;
  z-index: 99;
  height: 62.5px;
  padding: 0 23.75px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
</style>

<template>
  <a-layout-header class="basic-header">
    <user-menu></user-menu>
  </a-layout-header>
</template>

<script>
import UserMenu from "@/components/menu/UserMenu.vue";
export default {
  components: {
    UserMenu
  }
};
</script>