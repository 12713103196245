const Storage = {
  get: function (key) {
    var value = localStorage.getItem(key);
    if (value) {
      try {
        var value_json = JSON.parse(value);
        if (typeof value_json === 'object') {
          return value_json;
        } else if (typeof value_json === 'number') {
          return value_json;
        }
      } catch (e) {
        return value;
      }
    } else {
      return null;
    }
  },
  set: function (key, value) {
    let newValue = value;
    if (typeof value === 'object') {
      newValue = JSON.stringify(value)
    }
    localStorage.setItem(key, newValue);
  },
  // 仅支持array
  unshift: function (key, value, params) {
    var newValue;
    var oldValue = this.get(key) || null;
    if (oldValue) {
      if (oldValue instanceof Array) {
        newValue = oldValue;
        let hasIndex;
        if (params) {
          hasIndex = oldValue.findIndex(item => item[params] === value[params]);
          // console.log(hasIndex)
        } else {
          hasIndex = oldValue.findIndex(item => item === value);
        }
        if (hasIndex > -1) {
          newValue.splice(hasIndex, 1)
        }
        newValue.unshift(value);
        this.set(key, newValue)
      }
    } else {
      let arr = [];
      arr.unshift(value);
      this.set(key, arr)
    }
  },
  remove: function (key) {
    localStorage.removeItem(key);
  },
  clear: function () {
    localStorage.clear();
  }
};

// 批量保存，data是一个字典
Storage.setList = function (data) {
  for (var i in data) {
    localStorage.setItem(i, data[i]);
  }
};
// 批量删除，list是一个数组
Storage.removeList = function (list) {
  for (var i = 0, len = list.length; i < len; i++) {
    localStorage.removeItem(list[i]);
  }
};
export default Storage