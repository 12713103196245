<!--
 * @Author: LazyQ
 * @Date: 2020-04-12 10:11:40
 * @LastEditTime: 2020-04-17 21:46:50
 * @LastEditors: LazyQ
 -->
<template>
  <a-menu theme="light" mode="inline" :defaultSelectedKeys="[currentRoute]" @click="onSelect">
    <a-menu-item class="menu" :key="item.name" v-for="item in routeData" v-show="item.name!='admin' || (roleId==1 && item.name=='admin')">
      <a-icon :type="iconData[item.name]" />
      <!-- <icon-font type="icon-weibiaoti38" style="font-size:12px" /> -->
      <span class="nav-text">{{item.meta.title}}</span>
    </a-menu-item>
  </a-menu>
</template>

<script>
import routes from "@/router/routes";
import { Icon } from "ant-design-vue";

const IconFont = Icon.createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_1758480_3k4bp8wudqp.js"
});
export default {
  name: "SiderMenu",
  components: {
    IconFont,
  },
  data() {
    return {
      routeData: [],
      iconData: {},
      currentRoute: "",
      showAdmin: true,
    };
  },
  watch: {
    $route: {
      deep: true,
      handler(newVal, oldVal) {
        // console.log(newVal);
      }
    }
  },
  created() {
    this.routeData = routes[0].children;
    this.currentRoute = this.$route.name;
    this.iconData = iconArr;
    console.log(this.iconData);
  },
  methods: {
    onSelect(obj) {
      this.$router.push({ name: obj.key });
      this.$emit("menuSelect", obj);
    }
  }
};
const iconArr = {"project": "home", "member": "team", "customer": "user", "order": "container", "commodity": "shopping", "notice": "notification", "admin": "setting"};
</script>

<style lang="less">
.ant-menu {
  background: transparent !important;
  border-width: 0 !important;
  .ant-menu-item {
    margin: 0 !important;
    &::after {
      display: none !important;
    }
    .nav-text {
      font-size: 16px;
      font-weight: 500 !important;
      color: rgba(255, 255, 255, 1);
      margin-left: 10px;
    }
    .anticon {
      color: #fff !important;
    }
  }
  .ant-menu-item-selected {
    background: rgba(32, 86, 199, 1) !important;
  }
}
</style>
