/*
 * @Author: LazyQ
 * @Date: 2020-11-24 14:56:55
 * @LastEditTime: 2020-11-28 19:57:19
 * @LastEditors: LazyQ
 */
import Vue from 'vue'
import Antd from 'ant-design-vue';

import VueWechatTitle from 'vue-wechat-title'
import VueClipboard from 'vue-clipboard2'

// import './antd/index.js'
import 'amfe-flexible/index.js'
import 'ant-design-vue/dist/antd.css';
import '@assets/css/reset.css'
import router from './router/index'
import App from './App.vue'

Vue.prototype.$EventBus = new Vue();

import { createResetPasswordModal } from './widgets/modal/index'
Vue.prototype.$createResetPasswordModal = createResetPasswordModal

/// 用户信息
Vue.prototype.pwd = sessionStorage.getItem('password') || localStorage.getItem('password') || ''
Vue.prototype.adminId = sessionStorage.getItem('admin_id') || localStorage.getItem('admin_id') || '';
Vue.prototype.roleId = sessionStorage.getItem('role_id') || localStorage.getItem('role_id') || '';

Vue.use(VueClipboard)
Vue.use(VueWechatTitle);
Vue.use(Antd);
Vue.config.productionTip = false

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
