import https from '../utils/https'

// 会员登录
export function fetchAdminLogin(params) {
  return https.fetchPost('/api/backstage/admin/login', params)
}

// 管理员修改密码接口
export function fetchAdminModifypass(params) {
  return https.fetchPost('/api/backstage/admin/modifypass', params)
}

// 管理员重置密码接口
export function fetchAdminResetpass(params) {
  return https.fetchGet('/api/backstage/admin/resetpass', params)
}

// 创建管理员
export function fetchAdminCreate(params) {
  return https.fetchPost('/api/backstage/admin/create', params)
}

// 更新管理员
export function updateAdmin(params) {
  return https.fetchPost('/api/backstage/admin/update', params)
}

// 获取管理员详情
export function fetchAdminInfo(params) {
  return https.fetchGet('/api/backstage/admin/info', params)
}

// 管理员角色列表
export function fetchAdminRolelist(params) {
  return https.fetchPost('/api/backstage/admin/rolelist', params)
}

// 获取管理员列表
/*
* 1: 超级管理员
* 2: 普通管理员
* 3: 销售人员
* 4: 编程人员
* 5: 项目经理
* 6: 研究人员
* 7: DP
*/
export function fetchAdminlist(params) {
  return https.fetchGet('/api/backstage/admin/adminlist', params)
}
