/*
 * @Author: LazyQ
 * @Date: 2020-04-12 15:40:02
 * @LastEditTime: 2020-04-20 11:38:07
 * @LastEditors: LazyQ
 */
import BasicLayout from '@/layouts/BasicLayout.vue'

const routes = [
  {
    path: '/',
    name: 'project',
    redirect: {
      name: 'project',
    },
    component: BasicLayout,
    children: [
      {
        path: '/project',
        name: 'project',
        redirect: '/project/project',
        component: () => import('@/views/project/ProjectCenter.vue'),
        meta: { title: '项目管理', keepAlive: false, needLogin: true },
        children: [
          {
            path: 'project',
            name: 'project',
            component: () => import('@/views/project/Project.vue'),
            meta: { title: '项目管理', keepAlive: false, needLogin: true },
          },
          {
            path: 'projectDetail',
            name: 'projectDetail',
            component: () => import('@/views/project/ProjectDetail.vue'),
            meta: { title: '项目详情', keepAlive: false, needLogin: true },
          },
          {
            path: 'projectUrl',
            name: 'projectUrl',
            component: () => import('@/views/project/ProjectUrl.vue'),
            meta: { title: '项目链接配置', keepAlive: false, needLogin: true },
          }
        ]
      },
      {
        path: '/member',
        name: 'member',
        redirect: '/member/MemberList',
        component: () => import('@/views/member/Member.vue'),
        meta: { title: '会员管理', keepAlive: false, needLogin: true },
        children: [
          {
            path: 'member',
            name: 'member',
            component: () => import('@/views/member/MemberList.vue'),
            meta: { title: '会员管理', keepAlive: false, needLogin: true },
          },
          {
            path: 'memberDetail',
            name: 'memberDetail',
            component: () => import('@/views/member/MemberDetail.vue'),
            meta: { title: '会员基本信息', keepAlive: false, needLogin: true },
          }
        ]
      },
      {
        path: '/customer',
        name: 'customer',
        redirect: '/customer/customer',
        component: () => import('@/views/customer/Customer.vue'),
        meta: { title: '客户管理', keepAlive: false, needLogin: true },
        children: [
          {
            path: 'customer',
            name: 'customer',
            component: () => import('@/views/customer/Customerlist.vue'),
            meta: { title: '客户管理', keepAlive: false, needLogin: true },
          },
          {
            path: 'customerDetail',
            name: 'customerDetail',
            component: () => import('@/views/customer/CustomerDetail.vue'),
            meta: { title: '客户详情', keepAlive: false, needLogin: true },
          }
        ]
      },
      {
        path: 'order',
        name: 'order',
        component: () => import('@/views/order/Order.vue'),
        meta: { title: '订单管理', keepAlive: false, needLogin: true }
      },
      {
        path: '/commodity',
        name: 'commodity',
        redirect: '/commodity/commodity',
        component: () => import('@/views/commodity/Commodity.vue'),
        meta: { title: '可兑换商品管理', keepAlive: false, needLogin: true },
        children: [
          {
            path: 'commodity',
            name: 'commodity',
            component: () => import('@/views/commodity/CommodityList.vue'),
            meta: { title: '商品管理', keepAlive: false, needLogin: true },
          },
          {
            path: 'commodityDetail',
            name: 'commodityDetail',
            component: () => import('@/views/commodity/CommodityDetail.vue'),
            meta: { title: '商品详情', keepAlive: false, needLogin: true },
          }
        ]
      },
      {
        path: '/notice',
        name: 'notice',
        redirect: '/notice/notice',
        component: () => import('@/views/notice/Notice.vue'),
        meta: { title: '公告管理', keepAlive: false, needLogin: true },
        children: [
          {
            path: 'notice',
            name: 'notice',
            component: () => import('@/views/notice/NoticeList.vue'),
            meta: { title: '公告管理', keepAlive: false, needLogin: true },
          },
          {
            path: 'noticeDetail',
            name: 'noticeDetail',
            component: () => import('@/views/notice/NoticeDetail.vue'),
            meta: { title: '公告详情', keepAlive: false, needLogin: true },
          }
        ]
      },
      {
        path: 'admin',
        name: 'admin',
        redirect: '/admin/admin',
        component: () => import('@/views/admin/Admin.vue'),
        meta: { title: '管理员管理', keepAlive: false, needLogin: true },
        children: [
          {
            path: 'admin',
            name: 'admin',
            component: () => import('@/views/admin/AdminList.vue'),
            meta: { title: '管理员管理', keepAlive: false, needLogin: true },
          },
          {
            path: 'adminDetail',
            name: 'adminDetail',
            component: () => import('@/views/admin/AdminDetail.vue'),
            meta: { title: '管理员详情', keepAlive: false, needLogin: true },
          }
        ]
      },
    ]
  },
];
export default routes;