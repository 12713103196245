import Vue from 'vue'

import resetPasswordComponent from './ResetPassword.vue'

const resetPasswordModalConstructor = Vue.extend(resetPasswordComponent)

function createResetPasswordModal() {
  return new Promise((resolve, reject) => {
    const resetPwdModalDom = new resetPasswordModalConstructor({
      el: document.createElement('div'),
      data() {
        return {
        }
      }
    })
    // 把 实例化的 toast.vue 添加到 body 里
    document.body.appendChild(resetPwdModalDom.$el)
    resetPwdModalDom.show()
    resetPwdModalDom.$on('refresh', val => {
      location.reload()
    })
    resetPwdModalDom.$on('hide', val => {
      let timer = setTimeout(() => {
        clearTimeout(timer)
        document.body.removeChild(resetPwdModalDom.$el)
      }, 500)
    })
  })
}

export {
  createResetPasswordModal,
}