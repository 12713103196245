<!--
 * @Author: LazyQ
 * @Date: 2020-04-12 16:59:47
 * @LastEditTime: 2020-11-28 15:03:25
 * @LastEditors: LazyQ
 -->
<style lang="less">
.login {
  padding: 0 78px;
  h3 {
    font-size: 30px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(34, 34, 34, 1);
    margin: 31.25px auto 0;
    text-align: center;
  }
}
.form-item {
  margin: 10px 0 0 0 !important;
}
.input-item {
  border-radius: 18px !important;
  background: rgba(238, 238, 238, 1) !important;
  border-width: 0 !important;
  &:focus,
  &:hover {
    background: #ffffff !important;
    border: 1px solid rgba(68, 125, 245, 1) !important;
  }
}
.login-form-button {
  height: 44px !important;
  background: rgba(44, 100, 219, 1) !important;
  border-radius: 25px !important;
  &:active {
    border: 3px solid rgba(169, 196, 255, 1) !important;
  }
}
.remomber {
  font-size: 11px !important;
  font-family: PingFangSC-Regular, PingFang SC;
}
.login-form-forgot {
  float: right;
}
</style>
<template>
  <div class="login">
    <h3>登录</h3>
    <a-form
      id="components-form-demo-normal-login"
      :form="form"
      class="login-form"
      @submit="handleSubmit"
    >
      <a-form-item class="form-item">
        <a-input
          class="input-item"
          v-decorator="[
          'username',
          { rules: [{ required: true, message: '请输入账号！' }] },
        ]"
          placeholder="输入账号：admin123"
        ></a-input>
      </a-form-item>
      <a-form-item class="form-item">
        <a-input
          class="input-item"
          v-decorator="[
          'password',
          { rules: [{ required: true, message: '请输入密码！!' }] },
        ]"
          type="password"
          placeholder="密码：admin456"
        ></a-input>
      </a-form-item>
      <a-form-item style="margin: 0">
        <a-checkbox
          class="remomber"
          style="color: rgba(102, 102, 102, 1)"
          v-decorator="[
          'remember',
          {
            valuePropName: 'checked',
            initialValue: false,
          },
        ]"
        >记住密码</a-checkbox>
        <!-- <div class="login-form-forgot remomber" style="color:rgba(68,125,245,1);" href>忘记密码了？</div> -->
      </a-form-item>
      <a-form-item class="form-item">
        <a-button
          block
          type="primary"
          html-type="submit"
          class="login-form-button"
          :loading="loading"
        >登入</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { fetchAdminLogin } from "@/api/admin";
import md5 from 'js-md5';
import Storage from "@/utils/storage";
export default {
  data() {
    return {
      redirect: "",
      loading: false
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "normal_login" });
  },
  created() {
    this.redirect = this.$route.query.redirect || "project";
  },
  methods: {
    handleSubmit(e) {
      const that = this;
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          that.fetchUserLogin(values)
        }
      });
    },
    async fetchUserLogin(value) {
      const that = this;
      this.loading = true;
      let { username, password, remember } = value;
      try {
        let result = await fetchAdminLogin ({
          account: username,
          password: md5(password),
        });  
        /// 数据保存
        if (remember) {
          Storage.set("password", md5(password));
          Storage.set("admin_id", result.admin.id);
          Storage.set("role_id", result.admin.role_id);
        }else {
          sessionStorage.setItem("password", md5(password));
          sessionStorage.setItem("admin_id", result.admin.id);
          sessionStorage.setItem("role_id", result.admin.role_id);
        }
        Storage.set("username", result.admin.admin_name);
        this.loading = false;
        this.$message.success("登录成功");
        setTimeout(() => {
          that.$router.replace({ name: that.redirect });
        }, 2000);
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
  }
};
</script>
<style>
</style>