<!--
 * @Author: LazyQ
 * @Date: 2020-04-12 16:37:34
 * @LastEditTime: 2020-04-15 14:52:31
 * @LastEditors: LazyQ
 -->
<style lang="less" scoped>
.user-menu {
  min-width: 145px;
  margin-right: 20px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  img {
    width: 38px;
    height: 38px;
    display: block;
  }
  .nickname {
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    margin: 0 0 0 7px;
    padding: 0 8px;
    // &:hover {
    //   background-color: #f7f7f7;
    // }
  }
}
#user-menu-wrapper {
  width: 120px !important;
}
</style>

<template>
  <a-dropdown placement="bottomRight">
    <div class="user-menu">
      <img src="@/assets/images/avatar.png" alt />
      <p class="nickname">{{username}}</p>
    </div>
    <a-menu slot="overlay" id="user-menu-wrapper">
      <a-menu-item @click="resetPwd">
        <a-icon type="edit" />
        <span>修改密码</span>
      </a-menu-item>
      <a-menu-item @click="logout">
        <a-icon type="logout" />
        <span>退出登录</span>
      </a-menu-item>
    </a-menu>
  </a-dropdown>
</template>

<script>
import Storage from "@/utils/storage";
export default {
  data() {
    return {
      username: ""
    };
  },
  created() {
    this.username = Storage.get("username") || "";
  },
  methods: {
    logout() {
      const that = this;
      this.$confirm({
        title: "友情提示",
        content: "确认退出登录？",
        okText: "确认",
        cancelText: "取消",
        onOk() {
          Storage.remove('password');
          Storage.remove('admin_id');
          Storage.remove('username');
          sessionStorage.removeItem('password')
          sessionStorage.removeItem('admin_id')
          that.$router.replace({ name: "login" });
        },
        onCancel() {}
      });
    },
    resetPwd() {
      this.$createResetPasswordModal();
    }
  },
};
</script>